import EventSubscription from "./event-subscription";
import ToggleAllEvent from "../toggle-all.event";
import TodoEventsConstants from "../../constants/todo-events.constants";

class ToggleAllTodosEventSubscription  extends EventSubscription<ToggleAllEvent> {
    constructor(callback: (item: ToggleAllEvent) => void) {
        super(TodoEventsConstants.toggleAll, callback);
    }
}

export default ToggleAllTodosEventSubscription;