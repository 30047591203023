import CommonEvent from "../common.event";

abstract class EventSubscription<T extends CommonEvent> {
    private readonly _key: string;

    private readonly _callback: (item: T) => void;

    protected constructor(key: string, callback: (item: T) => void) {
        this._key = key;

        this._callback = callback;
    }

    get key() {
        return this._key;
    }

    get callback() {
        return this._callback;
    }
}

export default EventSubscription;