import EventSubscription from "./event-subscription";
import TodoEventsConstants from "../../constants/todo-events.constants";
import CloneTodoItemEvent from "../clone-todo-item.event";

class CloneTodoItemEventSubscription extends EventSubscription<CloneTodoItemEvent> {

    constructor(callback: (item: CloneTodoItemEvent) => void) {
        super(TodoEventsConstants.cloneTodo, callback);
    }

}

export default CloneTodoItemEventSubscription;