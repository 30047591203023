import SubscriptionManager from "./subscription-manager";
import SubscriptionContext from "./subscription-context";
import CommonEvent from "../../models/events/common.event";
import EventSubscription from "../../models/events/subscriptions/event-subscription";
import SubscriptionDefinition from "../../models/events/subscription-definition";

class EventManager {

    private readonly _subManager: SubscriptionManager;

    private readonly _subDefinitions: SubscriptionDefinition<CommonEvent>[] = [];

    constructor() {
        this._subManager = new SubscriptionManager();
    }

    public subscribe<T extends CommonEvent>(context: SubscriptionContext, eventSub: EventSubscription<T>) {
        let definition = this.getSubDefinition(eventSub.key);

        if (!definition) {
            definition = new SubscriptionDefinition<CommonEvent>(eventSub.key);
            this._subDefinitions.push(definition);
        }

        const callback: (item: CommonEvent) => void = eventSub.callback as (item: CommonEvent) => void;

        const subscription = definition.subject.subscribe(callback);

        this._subManager.subscribe(context, subscription);
    }

    public dispatch<T extends CommonEvent>(event: T) {
        let definition = this.getSubDefinition(event.key);

        if (!definition) {
            return;
        }

        definition.subject.next(event);
    }

    public unsubscribe(context: SubscriptionContext) {
        this._subManager.unsubscribe(context);
    }

    private getSubDefinition(key: string): SubscriptionDefinition<CommonEvent> | undefined {
        return this._subDefinitions.find(d => d.key === key);
    }
}

export default EventManager;