import EventSubscription from "./event-subscription";
import UpdateTodoEvent from "../UpdateTodoEvent";
import TodoEventsConstants from "../../constants/todo-events.constants";

class UpdateTodoEventSubscription extends EventSubscription<UpdateTodoEvent>{

    constructor(callback: (item: UpdateTodoEvent) => void) {
        super(TodoEventsConstants.updateTodo, callback);
    }
}

export default UpdateTodoEventSubscription;