import CommonEvent from "./common.event";
import TodoEventsConstants from "../constants/todo-events.constants";

class ToggleAllEvent  extends CommonEvent {

    constructor() {
        super(TodoEventsConstants.toggleAll);
    }
}

export default ToggleAllEvent;
