import React from "react";
import TodoItemModel from "../../models/todo-item.model";
import TodoItem from "../TodoItem/TodoItem";

type ToPrintProps = {
    todos: TodoItemModel[]
};

export class ComponentToPrint extends React.PureComponent<ToPrintProps> {
    render() {

        const todoItems = this.props.todos.map(item => <TodoItem model={item} key={item.key}/>);

        let className = 'todo-list';

        return (
            <div className='print-section'>
                <h1>Todo items</h1>

                <ul className={className}>
                    {todoItems}
                </ul>
            </div>
        );
    }
}