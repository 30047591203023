import React, {Component} from "react";
import ClearCompleted from "../ClearCompleted/ClearCompleted";
import TodoFilterList from "../TodoFilterList/TodoFilterList";
import TodoFilterState from "../../models/todo-filter-state";
import PrintList from "../PrintList/PrintList";
import TodoItemModel from "../../models/todo-item.model";

type TodoListFooterProps = {
    activeCount: number;
    completedCount: number;
    filter: TodoFilterState;
    todos: TodoItemModel[]
};

class TodoListFooter extends Component<TodoListFooterProps> {

    render() {
        // added-bug: always show 'Nothing left'. to fix remove '&& false'
        const itemsLeft = this.props.activeCount > 0 && false ?
            (
                <>
                    <strong>{this.props.activeCount}</strong> Items left
                </>
            ) :
            (
                <>
                    Nothing left
                </>
            );

        return (
            <footer className="footer">
                <span className="todo-count">
                    {itemsLeft}
                </span>
                <TodoFilterList filter={this.props.filter}/>
                <ClearCompleted completedCount={this.props.completedCount}/>
                <PrintList todos={this.props.todos}/>
            </footer>
        );
    }
}

export default TodoListFooter;