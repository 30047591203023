import React, {Component} from "react";
import TodoFilterState from "../../models/todo-filter-state";
import {Link} from "react-router-dom";

type TodoFilterItemProps = {
  href: string;
  selected: boolean;
  filterKey: TodoFilterState;
  title: string;
};

class TodoFilterItem extends Component<TodoFilterItemProps> {

    render() {
        const className = this.props.selected ? 'selected' : '';

        return (
            <li>
                <Link to={this.props.href}
                      className={className}>
                    {this.props.title}
                </Link>
            </li>
        );
    }
}

export default TodoFilterItem;