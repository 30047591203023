import React, {Component} from 'react';
import './App.css';
import 'todomvc-common/base.css';
import 'todomvc-app-css/index.css';
import TodoListPage from "./pages/todoList/TodoListPage";
import {Route, RouteComponentProps, HashRouter} from 'react-router-dom';
import BrowserIdentifier from "./services/support/browser-identifier";
import {Container} from "inversify";
import TYPES from "./services/ioc/TYPES";
import {Provider} from "inversify-react";

class App extends Component {

    private readonly _browserIdentifier: BrowserIdentifier;

    private readonly _servicesProvider = new Container();


    constructor(props: any, context: any) {
        super(props, context);

        this._browserIdentifier = new BrowserIdentifier();
        this.initServices();
    }


    componentDidMount(): void {
        if (this._browserIdentifier.isOpera()) {
            document.body.style.background = '#FFFAF0';
        }

        if (this._browserIdentifier.isFirefox()) {
            let favicon: HTMLLinkElement = document.getElementById('favicon') as HTMLLinkElement;

            if (favicon) {
                favicon.href = '';

                console.log(favicon);
            }
        }
    }

    render() {

        const listKey = 'todo-test-key';

        return (

            <Provider container={this._servicesProvider}>
                <HashRouter>
                    <Route path='/:filter?' exact={true} component={(props: RouteComponentProps<any>) => {

                        return <TodoListPage
                            listKey={listKey}
                            urlFilter={props.match.params.filter}/>;
                    }}/>

                </HashRouter>
            </Provider>
        );
    }

    private initServices() {

        this._servicesProvider.bind<BrowserIdentifier>(TYPES.BrowserIdentifier)
            .toConstantValue(this._browserIdentifier);

    }
}

export default App;