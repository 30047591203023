import EventSubscription from "./event-subscription";
import NewTodoItemEvent from "../new-todo-item.event";
import TodoEventsConstants from "../../constants/todo-events.constants";

class NewTodoItemEventSubscription extends EventSubscription<NewTodoItemEvent> {

    constructor(callback: (item: NewTodoItemEvent) => void) {
        super(TodoEventsConstants.newTodo, callback);
    }

}

export default NewTodoItemEventSubscription;