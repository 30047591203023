import EventSubscription from "./event-subscription";
import ClearCompletedEvent from "../clear-completed.event";
import TodoEventsConstants from "../../constants/todo-events.constants";

class ClearCompletedEventSubscription extends EventSubscription<ClearCompletedEvent>{
    constructor(callback: (item: ClearCompletedEvent) => void) {
        super(TodoEventsConstants.clearCompleted, callback);
    }
}

export default ClearCompletedEventSubscription;