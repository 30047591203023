enum TodoEventsConstants {
    updateTodo = 'update-todo',
    clearCompleted = 'clear-completed',
    toggleAll = 'toggle-all',
    newTodo = 'new-todo',
    toggleSingle = 'toggle-single',
    removeTodo = 'remove-todo',
    cloneTodo = 'clone-todo',
}

export default TodoEventsConstants;