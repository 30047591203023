class SubscriptionContext {

    private readonly _key: string;

    constructor() {
        this._key = (new Date()).getTime() + '';
    }

    get key(){
        return this._key;
    }

}

export default SubscriptionContext;