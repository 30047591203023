import EventManager from "../../services/events/event-manager";
import NewTodoItemEventSubscription from "../../models/events/subscriptions/new-todo-item.event-subscription";
import NewTodoItemEvent from "../../models/events/new-todo-item.event";
import TodoItemModel from "../../models/todo-item.model";
import ToggleAllTodosEventSubscription from "../../models/events/subscriptions/toggle-all-todos-event.subscription";
import ToggleAllEvent from "../../models/events/toggle-all.event";
import ToggleSingleEventSubscription from "../../models/events/subscriptions/toggle-single.event-subscribtion";
import ToggleSingleEvent from "../../models/events/toggle-single.event";
import RemoveTodoEventSubscription from "../../models/events/subscriptions/remove-todo-event.subscription";
import RemoveTodoEvent from "../../models/events/remove-todo.event";
import SubscriptionContext from "../../services/events/subscription-context";
import TodoService from "../../services/todo.service";
import ClearCompletedEvent from "../../models/events/clear-completed.event";
import ClearCompletedEventSubscription from "../../models/events/subscriptions/clear-completed.event-subscription";
import UpdateTodoEventSubscription from "../../models/events/subscriptions/update-todo.event-subscription";
import UpdateTodoEvent from "../../models/events/UpdateTodoEvent";
import CloneTodoItemEvent from "../../models/events/clone-todo-item.event";
import CloneTodoItemEventSubscription from "../../models/events/subscriptions/clone-todo-item.event-subscription";

class TodoListEventsHandler {

    private readonly _eventManager: EventManager;

    private _eventContext = new SubscriptionContext();

    constructor(private _todoService: TodoService) {
        this._eventManager = new EventManager();
    }

    public destroy() {
        this._eventManager.unsubscribe(this._eventContext);
    }

    public initEvents(listKey: string, loadList: () => void): EventManager {

        this._eventManager.subscribe(this._eventContext, new NewTodoItemEventSubscription((event: NewTodoItemEvent) => {
            const todoItem = TodoItemModel.createNew(event.title);

            this._todoService
                .add(listKey, todoItem)
                .then(() => loadList());
        }));

        this._eventManager.subscribe(this._eventContext, new CloneTodoItemEventSubscription((event: CloneTodoItemEvent) => {
            const todoItem = TodoItemModel.createNew(event.title);

            this._todoService
                .add(listKey, todoItem)
                .then(() => loadList());
        }));

        this._eventManager.subscribe(this._eventContext, new ToggleAllTodosEventSubscription((event: ToggleAllEvent) => {

            this._todoService
                .toggleAll(listKey)
                .then(() => loadList());
        }));

        this._eventManager.subscribe(this._eventContext, new ToggleSingleEventSubscription((event: ToggleSingleEvent) => {
            this._todoService
                .toggleSingle(listKey, event.itemKey)
                .then(() => loadList());
        }));

        this._eventManager.subscribe(this._eventContext, new RemoveTodoEventSubscription((event: RemoveTodoEvent) => {
            this._todoService
                .remove(listKey, event.itemKey)
                .then(() => loadList());
        }));

        this._eventManager.subscribe(this._eventContext, new ClearCompletedEventSubscription((event: ClearCompletedEvent) => {
            this._todoService
                .clearCompleted(listKey)
                .then(() => loadList());
        }));

        this._eventManager.subscribe(this._eventContext, new UpdateTodoEventSubscription((event: UpdateTodoEvent) => {
            this._todoService
                .update(listKey, event.item)
                .then(() => loadList());
        }));

        return this._eventManager;
    }
}

export default TodoListEventsHandler;