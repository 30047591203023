import TodoItemModel from "../../models/todo-item.model";
import React, {Component} from "react";
import TodoService from "../../services/todo.service";
import TodoList from "../../components/TodoList/TodoList";
import TodoListHeader from "../../components/TodoListHeader/todo-list-header";
import TodoListFooter from "../../components/TodoListFooter/TodoListFooter";
import TodoItemState from "../../models/todo-item-state";
import {Provider} from "inversify-react";
import {Container} from "inversify";
import TodoListEventsHandler from "./todo-list.events-handler";
import EventManager from "../../services/events/event-manager";
import TYPES from "../../services/ioc/TYPES";
import TodoFilterState from "../../models/todo-filter-state";

type TodoListPageState = {
    todos: TodoItemModel[];
    loaded: boolean;
};

type TodoListPageProps = {
    listKey: string;
    urlFilter: string;
};

class TodoListPage extends Component<TodoListPageProps, TodoListPageState> {

    private _todoService: TodoService;

    private _eventManagerProvider = new Container();

    private _todoListEventsHandler: TodoListEventsHandler;

    constructor(props: any, context: any) {
        super(props, context);

        this._todoService = new TodoService();
        this._todoListEventsHandler = new TodoListEventsHandler(this._todoService);

        this.initEvents();

        this.state = {
            todos: [],
            loaded: false,
        };
    }


    componentDidUpdate(prevProps: Readonly<TodoListPageProps>, prevState: Readonly<TodoListPageState>, snapshot?: any): void {

        if (this.props.urlFilter !== prevProps.urlFilter) {
            this.loadList();
        }
    }

    componentDidMount(): void {
        this.loadList();
    }

    componentWillUnmount(): void {
        this._todoListEventsHandler.destroy();
    }

    render() {
        if (!this.state.loaded) {
            return (
                <h1>
                    Loading...
                </h1>);
        }

        const activeCount = this.state.todos.reduce((a, t) => t.state === TodoItemState.active ? a + 1 : a, 0);
        const completedCount = this.state.todos.reduce((a, t) => t.state === TodoItemState.completed ? a + 1 : a, 0);

        return (
            <Provider container={this._eventManagerProvider}>
                <div>
                    <TodoListHeader/>
                    <TodoList todos={this.state.todos}/>
                    <TodoListFooter activeCount={activeCount} completedCount={completedCount}
                                    filter={this.getFilter()}
                                    todos={this.state.todos}
                    />
                </div>
            </Provider>
        );
    }

    private initEvents() {

        const eventManager = this._todoListEventsHandler
            .initEvents(this.props.listKey, () => this.loadList());

        this._eventManagerProvider.bind<EventManager>(TYPES.TodoEventManager)
            .toConstantValue(eventManager);
    }

    private loadList() {

        this._todoService
            .getList(this.props.listKey)
            .then(todos => this.setState({
                todos: this.filterList(todos),
                loaded: true
            }));
    }

    private filterList(todos: TodoItemModel[]): TodoItemModel[] {
        switch (this.getFilter()) {
            case TodoFilterState.active:

                return todos
                    .filter(t => t.state === TodoItemState.active);
            case TodoFilterState.completed:
                return todos.filter(t => t.state === TodoItemState.completed);
            default:
                return todos;
        }
    }

    private getFilter(): TodoFilterState {

        switch (this.props.urlFilter) {
            case 'active':
                return TodoFilterState.active;
            case 'completed':
                return TodoFilterState.completed;
        }

        return TodoFilterState.all;
    }
}

export default TodoListPage;