import React, {Component, RefObject} from "react";
import TodoItemModel from "../../models/todo-item.model";
import TodoItemState from "../../models/todo-item-state";
import CssBuilder from "../../utils/css-class-constructor/css-builder";
import {resolve} from "inversify-react";
import TYPES from "../../services/ioc/TYPES";
import EventManager from "../../services/events/event-manager";
import ToggleSingleEvent from "../../models/events/toggle-single.event";
import RemoveTodoEvent from "../../models/events/remove-todo.event";
// import UpdateTodoEvent from "../../models/events/UpdateTodoEvent";
import {ENTER_KEY, ESCAPE_KEY} from "../../models/constants/keyboard";
import BrowserIdentifier from "../../services/support/browser-identifier";
import './TodoItem.css';
import UpdateTodoEvent from "../../models/events/UpdateTodoEvent";
import CloneTodoItemEvent from "../../models/events/clone-todo-item.event";

type TodoItemProps = {
    model: TodoItemModel;
};

type TodoItemComponentState = {
    editing: boolean;
    editText: string;
};

class TodoItem extends Component<TodoItemProps, TodoItemComponentState> {

    // @ts-ignore
    @resolve(TYPES.TodoEventManager) private _todoEventManager: EventManager;

    private readonly editFieldRef: RefObject<any>;

    private readonly _browserIdentifier: BrowserIdentifier;

    constructor(props: TodoItemProps, context: any) {
        super(props, context);

        this.state = {
            editing: false,
            editText: props.model.title
        };

        this.editFieldRef = React.createRef();

        this._browserIdentifier = new BrowserIdentifier();
    }


    componentDidUpdate(prevProps: Readonly<TodoItemProps>, prevState: Readonly<TodoItemComponentState>, snapshot?: any): void {
        if (prevState.editing || !this.state.editing) {
            return;
        }

        const node = this.editFieldRef.current;

        node.focus();
        node.setSelectionRange(node.value.length, node.value.length);
    }

    render() {

        const completed = this.props.model.state === TodoItemState.completed;

        const cssClasses = this.getCssClasses();

        return (
            <li className={cssClasses}>
                <div className="view">
                    <input
                        className="toggle"
                        type="checkbox"
                        checked={completed}
                        onChange={e => this._todoEventManager.dispatch(new ToggleSingleEvent(this.props.model.key))}
                    />

                    <label
                        onDoubleClick={e => this.handleEdit()}
                    >
                        {/*{this.props.model.title}*/}
                        {this.state.editText}
                    </label>

                    <button className="destroy"
                            onClick={e => this._todoEventManager.dispatch(new RemoveTodoEvent(this.props.model.key))}
                    />
                    <button className="clone"
                            onClick={e => this._todoEventManager.dispatch(new CloneTodoItemEvent(this.props.model.title))}
                    />
                </div>
                <input
                    ref={this.editFieldRef}
                    className="edit"
                    value={this.state.editText}
                    onBlur={e => this.handleSubmit(e)}
                    onChange={e => this.handleChange(e)}
                    onKeyDown={e => this.handleKeyDown(e)}
                />
            </li>
        );
    }

    private handleEdit() {
        this.setState({editing: true});
    }

    private handleSubmit(event: React.FormEvent) {
        const val = this.state.editText.trim();

        const newState = {editing: false};

        if (val) {
            const updatedEvent = new TodoItemModel(this.props.model.key, val, this.props.model.state);
            this._todoEventManager.dispatch(new UpdateTodoEvent(updatedEvent));

            this.setState({...newState, ...{editText: val}});

            return;
        }

        this._todoEventManager.dispatch(new RemoveTodoEvent(this.props.model.key));

        this.setState(newState);
    }

    private handleChange(event: React.FormEvent) {
        const input: any = event.target;
        this.setState({editText: input.value});
    }

    private handleKeyDown(event: React.KeyboardEvent) {
        if (event.keyCode === ESCAPE_KEY) {
            this.setState({
                editText: this.props.model.title,
                editing: false
            });
        } else if (event.keyCode === ENTER_KEY) {
            this.handleSubmit(event);
        }
    }

    private getCssClasses(): string {

        let css = CssBuilder
            .create()
            .add(
                () => this.props.model.state === TodoItemState.completed,
                'completed')
            .add(
                () => this.state.editing,
                'editing');

        if (this._browserIdentifier.isOpera()) {
            css = css.add(() => true, 'todo-item-line');
        }

        return css.build();
    }
}

export default TodoItem;