import SubscriptionContext from "./subscription-context";
import {Subscription} from "rxjs";

class SubscriptionManager {

    private _contextSubscription: any;

    constructor() {
        this._contextSubscription = {};
    }

    subscribe(context: SubscriptionContext, subscription: Subscription) {

        const prevSubs = this._contextSubscription[context.key];

        const subscriptions = [...(!!prevSubs ? prevSubs : []), subscription];

        this._contextSubscription[context.key] = subscriptions;
    }

    unsubscribe(context: SubscriptionContext){
        const subscriptions: Subscription[] = this._contextSubscription[context.key];

        subscriptions.forEach(s => s.unsubscribe());
    }
}

export default SubscriptionManager;