import TodoItemModel from "../models/todo-item.model";
import TodoItemState from "../models/todo-item-state";

class TodoService {

    getList(key: string): Promise<TodoItemModel[]> {

        const todos = this.getStored(key);

        return Promise.resolve(todos);
    }

    add(key: string, todoItem: TodoItemModel): Promise<any> {

        const todos = this.getStored(key);

        todos.push(todoItem);

        return this.save(key, todos);
    }

    toggleSingle(key: string, itemKey: string) {
        const todos = this.getStored(key);

        const item = todos.find(t => t.key === itemKey);

        if (!item) {
            return  Promise.resolve();
        }

        if (item.state === TodoItemState.active) {
            item.state = TodoItemState.completed;
        } else {
            item.state = TodoItemState.active;
        }

        return this.save(key, todos);
    }

    remove(key: string, itemKey: string) {
        const todos = this.getStored(key);

        const itemIndex = todos.findIndex(t => t.key === itemKey);

        todos.splice(itemIndex, 1);

        return this.save(key, todos);
    }

    toggleAll(key: string) {
        const todos = this.getStored(key);

        const hasActive = todos.some(t => t.state === TodoItemState.active);

        const state = hasActive ? TodoItemState.completed : TodoItemState.active;

        todos.forEach(item => item.state = state);

        return this.save(key, todos);
    }

    clearCompleted(key: string) {
        const todos = this.getStored(key);

        const notCompleted = todos.filter(t => t.state !== TodoItemState.completed);

        return this.save(key, notCompleted);
    }

    update(key: string, item: TodoItemModel) {
        const todos = this.getStored(key);

        const storedItem = todos.find(t => t.key === item.key);

        if (!storedItem){
            return Promise.reject();
        }

        storedItem.title = item.title;

        return this.save(key, todos);

    }

    private save(key: string, todos: TodoItemModel[]) {
        localStorage.setItem(key, JSON.stringify(todos));

        return Promise.resolve();
    }

    private getStored(key: string): TodoItemModel[] {
        const list = localStorage.getItem(key);

        const todos: TodoItemModel[] = (!!list ? JSON.parse(list) : []);

        return todos;
    }
}

export default TodoService;