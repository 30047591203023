import React, {Component} from "react";
import './PrintList.css';
import ReactToPrint from "react-to-print";
import {ComponentToPrint} from "./ComponentToPrint";
import TodoItemModel from "../../models/todo-item.model";

type PrintListProps = {
    todos: TodoItemModel[]
};

type PrintListState = {

};


class PrintList extends Component<PrintListProps, PrintListState> {

    private componentRef: any;


    render() {

        return (
            <div>
                <ReactToPrint
                    trigger={() => {
                        return <button className="print-list">
                            Print
                        </button>;
                    }}
                    content={() => this.componentRef}
                />

                <div className="print-container">
                    <ComponentToPrint todos={this.getTodosToPrint()} ref={el => (this.componentRef = el)}/>
                </div>

            </div>
        );
    }

    private getTodosToPrint() {
        //added-bug: print first 10 only
        return this.props.todos.slice(0, 10);
    }
}

export default PrintList;