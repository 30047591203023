import CommonEvent from "./common.event";
import TodoEventsConstants from "../constants/todo-events.constants";

class ToggleSingleEvent extends CommonEvent{

    constructor(public itemKey: string) {
        super(TodoEventsConstants.toggleSingle);
    }
}

export default ToggleSingleEvent;