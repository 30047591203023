import CssClassDefinition from "./css-class-definition";
import {CssDefinitionChecker} from "./css-definition-checker";

class CssBuilder {
    private readonly _definitions: CssClassDefinition[];

    protected constructor(definitions: CssClassDefinition[]) {
        this._definitions = definitions;
    }

    public static create(): CssBuilder {
        return new CssBuilder([]);
    }

    public add(checker: CssDefinitionChecker, className: string): CssBuilder {

        const definition = new CssClassDefinition(checker, className);

        return new CssBuilder([...this._definitions, definition]);
    }

    public build(): string {

        return this._definitions
            .reduce(
                (acc, def) => acc + (def.checker() ? ' ' + def.className : ''),
                '');
    }
}

export default CssBuilder;