class CommonEvent {
    private readonly _key: string;

    protected constructor(key: string) {
        this._key = key;
    }

    get key() {
        return this._key;
    }
}

export default CommonEvent;