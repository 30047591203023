import React, {Component} from "react";
import TodoFilterItem from "../TodoFilterItem/TodoFilterItem";
import TodoFilterState from "../../models/todo-filter-state";

type TodoFilterListProps = {
    filter: TodoFilterState;
};

class TodoFilterList extends Component<TodoFilterListProps> {


    render() {

        return (

            <ul className="filters">
                {this.createItem("/", TodoFilterState.all, 'All')}
                {this.createItem("/active", TodoFilterState.active, 'Active')}
                {this.createItem("/completed", TodoFilterState.completed, 'Completed')}
            </ul>
        );
    }

    private createItem(href: string, key: TodoFilterState, title: string) {

        const currentFilter = this.props.filter;

        return <TodoFilterItem href={href} selected={currentFilter === key} filterKey={key} title={title}/>;
    }
}

export default TodoFilterList;