import TodoItemState from "./todo-item-state";

class TodoItemModel {

    constructor(public key: string, public title: string, public state: TodoItemState) {
    }

    public static createNew(title: string): TodoItemModel {

        const key = (new Date()).getTime() + '';

        return new TodoItemModel(key, title, TodoItemState.active);
    }
}

export default TodoItemModel;