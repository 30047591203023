import {Subject} from "rxjs";
import CommonEvent from "./common.event";

class SubscriptionDefinition<T extends CommonEvent> {

    private readonly _key: string;

    private readonly _subject: Subject<T> = new Subject();

    constructor(key: string) {
        this._key = key;
    }

    public get key() {
        return this._key;
    }

    public get subject() {
        return this._subject;
    }
}

export default SubscriptionDefinition;