import EventSubscription from "./event-subscription";
import TodoEventsConstants from "../../constants/todo-events.constants";
import ToggleSingleEvent from "../toggle-single.event";

class ToggleSingleEventSubscription extends EventSubscription<ToggleSingleEvent> {

    constructor(callback: (item: ToggleSingleEvent) => void) {
        super(TodoEventsConstants.toggleSingle, callback);
    }
}

export default ToggleSingleEventSubscription;