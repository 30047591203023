import React, {Component} from 'react';
import TodoItemModel from "../../models/todo-item.model";
import TodoItemState from "../../models/todo-item-state";
import TodoItem from "../TodoItem/TodoItem";
import {resolve} from "inversify-react";
import TYPES from "../../services/ioc/TYPES";
import EventManager from "../../services/events/event-manager";
import ToggleAllEvent from "../../models/events/toggle-all.event";
import BrowserIdentifier from "../../services/support/browser-identifier";
import './TodoList.css';

type TodoListProps = {
    todos: TodoItemModel[]
};

class TodoList extends Component<TodoListProps> {

    // @ts-ignore
    @resolve(TYPES.TodoEventManager) private _todoEventManager: EventManager;

    // @ts-ignore
    @resolve(TYPES.BrowserIdentifier) private _browserIdentifier: BrowserIdentifier;

    render() {

        const activeTodoCount = this.props.todos.reduce((acc, item) => item.state === TodoItemState.active ? 1 : 0, 0);

        const todoItems = this.props.todos.map(item => <TodoItem model={item} key={item.key}/>);

        const toggleAllClassName = this._browserIdentifier.isFirefox() ? 'ff-toggle-all' : '';

        let className = 'todo-list';

        if (this._browserIdentifier.isOpera()) {
            className += ' todo-list-line';
        }

        return (
            <section className="main">
                <input
                    id="toggle-all"
                    className="toggle-all"
                    type="checkbox"
                    onChange={e => this.toggleAll(e)}
                    checked={activeTodoCount === 0}
                />
                <label className={toggleAllClassName}
                    htmlFor="toggle-all"
                >
                    Mark all as complete
                </label>
                <ul className={className}>
                    {todoItems}
                </ul>
            </section>
        );
    }

    private toggleAll(event: React.FormEvent) {
        this._todoEventManager.dispatch(new ToggleAllEvent());
    }
}

export default TodoList;