import EventSubscription from "./event-subscription";
import TodoEventsConstants from "../../constants/todo-events.constants";
import RemoveTodoEvent from "../remove-todo.event";

class RemoveTodoEventSubscription extends EventSubscription<RemoveTodoEvent>{

    constructor(callback: (item: RemoveTodoEvent) => void) {
        super(TodoEventsConstants.removeTodo, callback);
    }
}

export default RemoveTodoEventSubscription;