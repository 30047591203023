import CommonEvent from "./common.event";
import TodoEventsConstants from "../constants/todo-events.constants";
import TodoItemModel from "../todo-item.model";

class UpdateTodoEvent extends CommonEvent {

    constructor(public item: TodoItemModel) {
        super(TodoEventsConstants.updateTodo);
    }
}

export default UpdateTodoEvent;