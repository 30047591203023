class BrowserIdentifier {

    public isOpera(): boolean {

        // @ts-ignore
        return (!!window.opr && !!window.addons) || !!window.opera
            || navigator.userAgent.indexOf(' OPR/') >= 0;

    }

    public isFirefox(): boolean {
        // @ts-ignore
        return typeof InstallTrigger !== 'undefined';
    }

    public isIE(): boolean {
        // @ts-ignore
        return false || !!document.documentMode;
    }

    public isEdge(): boolean {

        // @ts-ignore
        return !this.isIE() && !!window.StyleMedia;
    }

    public isSafari(): boolean {

        // @ts-ignore
        if (/constructor/i.test(window.HTMLElement)){
            return  true;
        }

        let checker = (p: any) => { return p.toString() === "[object SafariRemoteNotification]"; };

       // @ts-ignore
        return  checker(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    }

}

export default BrowserIdentifier;