import React, {Component} from "react";
import {resolve} from "inversify-react";
import TYPES from "../../services/ioc/TYPES";
import EventManager from "../../services/events/event-manager";
import ClearCompletedEvent from "../../models/events/clear-completed.event";
import BrowserIdentifier from "../../services/support/browser-identifier";

type ClearCompletedProps = {
    completedCount: number;
};

class ClearCompleted extends Component<ClearCompletedProps> {

    private readonly _browserIdentifier: BrowserIdentifier;

    constructor(props: Readonly<ClearCompletedProps>) {
        super(props);

        this._browserIdentifier = new BrowserIdentifier();
    }

// @ts-ignore
    @resolve(TYPES.TodoEventManager) private _todoEventManager: EventManager;

    render() {
        if (this.props.completedCount > 0) {
            return (
                <button className="clear-completed"
                    onClick={e => {

                        if (this._browserIdentifier.isEdge() || this._browserIdentifier.isSafari()) {
                            return;
                        }

                        this._todoEventManager.dispatch(new ClearCompletedEvent());
                    }}
                >
                    Clear completed
                </button>
            );
        }

        return (
            <></>
        );
    }
}

export default ClearCompleted;